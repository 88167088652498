<template>
    <div>
        <ShowcaseProducts :name="item.name" :products="item.products" v-for="item in showcases" :key="item.id"/>
    </div>
</template>

<script>
    import ShowcaseProducts from '~/components/ShowcaseProducts'

    export default {
        components: {
            ShowcaseProducts
        },
        data() {
            return {
                showcases: []
            }
        },
        async fetch() {
            let urls = [];
            if (!!this.$wdconfig.modules.showcase.home_area) {
                await this.$axios.$get(`/?p=ecommerce_produtos_showcases_areas_api_v1&area=${this.$wdconfig.modules.showcase.home_area}`, { cache: true }).then((res) => {
                    if (res.status === 'success' && res.data) {
                        res.data.forEach(el => {
                            urls.push(`api/v1/ecommerce/products/?showcase_code=${el.id}&limit=24&show_showcases=true&thumb=sim&x=340&show_payments=true`)
                        })
                    }
                }).catch((e) => {
                    console.error(e)
                })
            } else {
                urls.push(`api/v1/ecommerce/products/?showcase_code=${this.$wdconfig.modules.showcase.home}&limit=24&show_showcases=true&thumb=sim&x=340&show_payments=true`)
            }
            try {
                const promises = urls.map(url => this.$axios.$get(url))
                const responses = await Promise.all(promises)
                this.showcases = responses.map((res) => ({id: res.metadata.showcases[0].id, name: res.metadata.showcases[0].name, products: res.data}))
            } catch (e) {
                console.error(e)
            }
        }
    }
</script>
