<template>
    <div class="single-banner" v-if="banners.length">
        <swiper class="swiper" :options="swiperOption" ref="mySwiper">
            <swiper-slide v-for="(item, index) in banners" :key="item.id" :data-slider-id="item.id">
                <component :is="item.link ? 'a' : 'div'" v-bind="item.link ? {href: item.link, target: item.target} : ''" @click.prevent="item.link ? url_redirect(item.link, item.target) : ''" class="d-block w-100 mx-auto" style="max-width: 1920px">
                    <div class="bg-image"></div>
                    <div class="aspect-ratio ratio-9-3">
                        <div class="aspect-content">
                            <img :src="image_cache(item.file, item.file_cache1920)" width="1920" height="640" :alt="item.name" :title="item.name" class="object-fit-cover" :loading="index ? 'lazy' : 'eager'">
                        </div>
                    </div>
                </component>
            </swiper-slide>
            <div class="swiper-pagination" slot="pagination"></div>
            <div class="swiper-button-prev" slot="button-prev">
                <svg width="30px" height="30px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fill="none">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="3" d="M14 5l-7 7 7 7"/>
                </svg>
            </div>
            <div class="swiper-button-next" slot="button-next">
                <svg width="30px" height="30px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fill="none">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="3" d="M10 5l7 7-7 7"/>
                </svg>
            </div>
        </swiper>
    </div>
</template>

<script>
    import {Swiper, SwiperSlide} from 'vue-awesome-swiper'
    import utils from "~/mixins/utils";

    export default {
        components: {
            Swiper,
            SwiperSlide
        },
        mixins: [
            utils
        ],
        props: {
            banners: {
                default: () => [],
                type: Array
            },
            size: {
                type: String, Number,
                default: ''
            },
            loading: {
                type: String,
                default: ''
            }
        },
        data() {
            return {
                swiperOption: {
                    lazy: true,
                    autoplay: {
                        disableOnInteraction: false,
                        pauseOnMouseEnter: true,
                    },
                    loop: true,
                    pagination: {
                        el: '.swiper-pagination',
                        clickable: true
                    },
                    navigation: {
                        nextEl: '.swiper-button-next',
                        prevEl: '.swiper-button-prev'
                    }
                }
            }
        }
    }
</script>
