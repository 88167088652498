<template>
    <div class="brands-slider pb-5" v-if="brands.length">
        <b-container>
            <div class="title text-center mb-5"> Filtre por marcas</div>
            <client-only>
                <swiper class="swiper brands-swiper px-4" :options="swiperOption" ref="swiper"
                        :auto-update="true"
                        :auto-destroy="true"
                        :delete-instance-on-destroy="true"
                        :cleanup-styles-on-destroy="true"
                >
                    <swiper-slide v-for="(brand, index) in brands" :key="index">
                        <div class="mx-4">
                            <nuxt-link :to="`/busca/?filter_brands=[${brand.id}]`">
                                <img :src="imageUrl(brand.image)" class="img-fluid object-fit-contain img-brand" :alt="brand.name" :title="brand.name" loading="lazy">
                            </nuxt-link>
                        </div>
                    </swiper-slide>
                    <div class="swiper-button-prev mx-0" slot="button-prev">
                        <svg width="30px" height="30px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fill="none">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="3" d="M14 5l-7 7 7 7"/>
                        </svg>
                    </div>
                    <div class="swiper-button-next mx-0" slot="button-next">
                        <svg width="30px" height="30px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fill="none">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="3" d="M10 5l7 7-7 7"/>
                        </svg>
                    </div>
                </swiper>
            </client-only>
        </b-container>
    </div>
</template>
<script>
    import utils from '~/mixins/utils';

    export default {
        mixins: [
            utils
        ],
        data() {
            return {
                brands: []
            }
        },
        computed: {
            swiperOption() {
                return {
                    infinite: true,
                    loop: true,
                    lazy: true,
                    centerInsufficientSlides: true,
                    navigation: {
                        nextEl: '.swiper-button-next',
                        prevEl: '.swiper-button-prev'
                    },
                    breakpoints: {
                        1440: {
                            slidesPerView: 5
                        },
                        1024: {
                            slidesPerView: 4
                        },
                        768: {
                            slidesPerView: 2
                        },
                        640: {
                            slidesPerView: 1
                        },
                        320: {
                            slidesPerView: 1
                        }
                    }
                }
            }
        },
        async fetch() {
            await this.$axios.get('/?p=ecommerce_produtos_brands_api_v1&filter[showcase]=true&filter[products]=true', { cache: true }).then((res) => {
                if ('data' in res.data && res.data.status === 'success') {
                    this.brands = res.data.data
                }
            }).catch((e) => {
                console.error(e)
            })
        },
    }
</script>
