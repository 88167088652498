<template>
    <div class="reviews-slider" v-if="reviews.length">
        <b-container>
            <b-row class="reviews-container">
                <div class="col-lg-3 my-2">
                    <h1 class="title">{{ content.name1 }}</h1>
                    <div class="stars d-flex">
                        <b-icon v-for="star in 5" class="mr-3" icon="star-fill" scale="1.5" :key="star"></b-icon>
                    </div>
                    <div class="d-block review-link" v-html="content.text1"></div>
                </div>
                <div class="col-lg-9 my-2">
                    <swiper class="swiper px-4" :options="swiperOption" ref="swiper" :auto-update="true" :auto-destroy="true" :delete-instance-on-destroy="true" :cleanup-styles-on-destroy="true">
                        <swiper-slide v-for="review in reviews" :key="review.id" class="d-flex justify-content-center">
                            <div class="review-card">
                                <div class="font-weight-bold">{{ review.name }}</div>
                                <div v-html="review.text1"></div>
                            </div>
                        </swiper-slide>
                        <div class="swiper-button-prev mx-0" slot="button-prev">
                            <svg width="30px" height="30px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fill="none">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="3" d="M14 5l-7 7 7 7" />
                            </svg>
                        </div>
                        <div class="swiper-button-next mx-0" slot="button-next">
                            <svg width="30px" height="30px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fill="none">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="3" d="M10 5l7 7-7 7" />
                            </svg>
                        </div>
                    </swiper>
                </div>
            </b-row>
        </b-container>
    </div>
</template>
<script>
    import { Swiper, SwiperSlide } from "vue-awesome-swiper";
    export default {
        components: {
            Swiper,
            SwiperSlide
        },
        data() {
            return {
                content: {},
                reviews: []
            };
        },
        computed: {
            swiperOption() {
                return {
                    infinite: true,
                    loop: true,
                    lazy: true,
                    centerInsufficientSlides: true,
                    navigation: {
                        nextEl: '.swiper-button-next',
                        prevEl: '.swiper-button-prev'
                    },
                    breakpoints: {
                        1440: {
                            slidesPerView: 2
                        },
                        1024: {
                            slidesPerView: 2
                        },
                        992: {
                            slidesPerView: 2
                        },
                    }
                };
            }
        },
        async fetch() {
            if (!!this.$wdconfig.modules.cms.page_id_reviews) {
                await this.$axios.$get(`api/v1/cms/?page_id=${this.$wdconfig.modules.cms.page_id_reviews}&categories_content=true`, { cache: true }).then((res) => {
                    if (res.status === 'success') {
                        this.content = res.data;
                        this.reviews = res.data.category1.list;
                    }
                }).catch(e => {
                    console.error(e);
                });
            }
        }
    };
</script>
